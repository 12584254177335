<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="wet-section">
      <div class="container">
        <div class="wet-section-title text-center">
          <h1 class="empty-ph">
            {{forgot.header_title}}
          </h1>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-lg-5">
            <form @submit.prevent="submit">
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-reset-password-email"
                  type="email"
                  :placeholder="loginStrings.email"
                  v-model.trim="$v.formData.email.$model"
                >
                <label for="wet-reset-password-email">
                  {{ loginStrings.email }}
                </label>
              </div>
              <div
                v-if="$v.formData.email.$error"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ loginStrings.error_email }}
              </div>
              <div class="d-flex">
                <button
                  id="back-button"
                  type="button"
                  class="wet-button wet-button-flat empty-ph"
                  style="min-width:4rem;"
                  @click.prevent="back"
                >{{ commonApp.back_text }}</button>
                <button
                  id="wet-button-signup"
                  type="submit"
                  class="wet-button empty-ph ml-3 flex-fill"
                  :disabled="$v.$invalid"
                >{{ forgot.btn_reset }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import wpSection from '@/enums/wpSection';

export default {
  mixins: [common],
  data: () => ({
    wpLoginApi: '/wp-json/acf/v3/options/login',
    wpForgotApi: '/wp-json/acf/v3/options/forgot',
    resetPwdApi: '/api/user/send_reset_email',
    formData: {
      email: '',
    },
  }),
  computed: {
    ...mapState(['strings']),
    forgot() {
      return this.strings.forgot || {};
    },
    commonApp() {
      return this.strings.commonApp || {};
    },
    loginStrings() {
      return this.strings.login || {};
    },
  },
  async created() {
    await this.getWordPressStrings([wpSection.LOGIN, wpSection.FORGOT], wpSection.FORGOT);
  },
  validations() {
    return {
      formData: {
        email: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) return;

      const message = this.forgot.success_status;
      this.resetPwd()
        .finally(() => {
          const modal = {
            id: 'wet-reset-pwd-email-modal',
            content: `<div class="text-center">${message}</div>`,
            actions: [
              {
                name: 'wet-reset-pwd-email-modal-ok',
                title: this.commonApp?.ok,
                event: 'close',
              },
            ],
            close: this.onCloseModal,
          };
          this.$store.dispatch('addModal', modal);
        });
    },
    async resetPwd() {
      const url = this.resetPwdApi;
      const data = {
        email: this.formData.email,
      };
      await this.callApi({ url, data, method: 'POST' });
    },
    back() {
      this.$router.back();
    },
    onCloseModal() {
      this.$router.push({ name: 'login', params: { ...this.$route.params } });
    },
  },
};
</script>

<style>

</style>
